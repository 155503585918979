@import "_variables.scss";

body {
  background-color: #FFF;
  color: #000;
  font-family: $opensans;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  border-style: none;
}

.overflow {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.breadcrumb {
  background: #000;
  border-top: 1px solid #161718;
  padding: 10px 0;
  color: #545b5e;
  text-transform: uppercase;
  border-radius: 0;
  margin: 0 auto;
  font: 400 11px/1.2em $opensans;
  @include tablet {
    font-size: 13px;
  }

  .container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;

    a {
      color: #545b5e;
    }

    strong {
      color: #899596;
    }
  }
}

.header-text {
  background: #F0F1F1;
  padding: 20px 0;
  margin: 0 auto 20px;
  @include tablet {
    padding: 30px 0;
    margin: 0 auto 30px;
  }
  @include desktop {
    padding: 40px 0;
    margin: 0 auto 40px;
  }

  h1.header {
    font-weight: bold;
    text-align: center;
    margin: 0 0 15px;
    @include tablet {
      margin: 0 auto 20px;
    }

    &.md {
      font-size: 20px;
      @include tablet {
        font-size: 32px;
      }
      @include desktop {
        font-size: 44px;
      }
      @include large-desktop {
        font-size: 50px;
      }
    }

    &.sm {
      font-size: 18px;
      text-align: left;
      @include tablet {
        font-size: 22px;
      }
      @include desktop {
        font-size: 30px;
      }
      @include large-desktop {
        font-size: 38px;
      }
    }
  }

  p {

    &.live-info {
      font-size: 13px;
      margin: 10px 0;
      @include tablet {
        text-align: right;
        font-size: 14px;
      }
      @include desktop {
        font-size: 20px;
      }
    }

    &.header {
      font-weight: 400;
      margin: 0 auto;
      text-align: center;
      max-width: 100%;
      line-height: 1.3em;
      @include tablet {
        max-width: 700px;
      }
      @include desktop {
        max-width: 800px;
      }
      @include large-desktop {
        max-width: 900px;
      }

      a {
        color: $glBlue;
      }

      &.lg {
        font-size: 15px;
        @include tablet {
          font-size: 20px;
        }
        @include desktop {
          font-size: 22px;
        }
        @include large-desktop {
          font-size: 24px;
        }
      }

      &.md {
        font-size: 15px;
        @include tablet {
          font-size: 16px;
        }
        @include desktop {
          font-size: 17px;
        }
      }

      &.sm {
        margin: 0;
        font-size: 13px;
        text-align: left;
        max-width: 600px;
        line-height: normal;
        text-transform: uppercase;
        @include tablet {
          font-size: 14px;
        }
        @include desktop {
          font-size: 18px;
        }
        @include large-desktop {
          font-size: 20px;
        }

        a {
          font-weight: bold;
        }
      }
    }
  }

  .view-all-button,
  .next-step-button {
    text-transform: uppercase;
    text-decoration: none;
    font: 700 12px $opensans;
    padding: 10px 10px;
    text-align: center;
    border: 2px solid;
    border-radius: 5px;
    position: relative;
    top: 0;
    color: #000;
    margin: 15px 0.5% 0;
    float: left;
    width: 49%;
    @include tablet {
      margin: 0 0.5%;
      padding: 15px 0;
      font-size: 13px;
      float: right;
    }
    @include desktop {
      font-size: 16px;
    }

    &:hover {
      background-color: #000;
      color: #fff;
      border-color: #000;
    }

    i {
      margin: 0 5px 0 0;
    }
  }

  .next-step-button {
    background: $glYellow;
    color: #000;
    border-color: $glYellow;
  }

  .live-logo {
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 0 auto 15px;
    @include tablet {
      margin: 0 auto 20px;

      max-width: 550px;
      display: block;
    }
    @include desktop {

      max-width: 700px;
    }
  }
}

.ad-option {
  margin: -5px auto 20px;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  background: #FFF;
  cursor: pointer;
  @include tablet {

    margin: -20px auto 20px;
  }
  @include desktop {
    margin: -30px auto 20px;

  }
  @include large-desktop {
    margin: -30px auto 30px;

  }

  &.white-outline {
    border: 1px solid #FFF;
  }

  &.side {
    margin: 0 auto 10px;
    width: 100%;
    max-width: 480px;
  }

  img {
    width: 100%;
    -webkit-transition: opacity .3s ease-in;
    -ms-transition: opacity .3s ease-in;
    transition: opacity .3s ease-in;
  }

  &:hover img {
    opacity: 0.9;
  }
}

.banner-include > div {
  padding:0 0 15px;
  width:100%;
  overflow:hidden;
}

.banner-include-top > div {
  padding:15px 0 0;
}

/* ------CONTENT BOX */
.content-box {
  width: 100%;
  height: auto;
  margin: 0 0 15px;
  float: left;
  @include tablet {
    margin: 0 0 20px;
  }
  @include desktop {
    margin: 0 0 30px;
  }

  &.broadcasting {
    padding: 10px;

    .video {
      margin: 0;
    }
  }

  hr {
    border-color: #ccc;
    margin: 30px auto 0;
    @include desktop {
      margin: 30px auto;
    }
  }

  iframe {
    max-width:100%;
  }
}

.content {
  position: relative;
  z-index: 1;

  &.lesson-content {
    padding-bottom: 20px;
    overflow: hidden;
    @include tablet {
      padding-bottom: 30px;
    }
    @include desktop {
      padding-bottom: 40px;
    }

    .row {
      position: relative;
      .content-box {
        @include desktop {
          min-height:790px;
        }
        @include large-desktop {
          min-height:850px;
        }
      }
    }
  }

  a {
    color: $glBlue;

    &:hover {
      color: lighten($glBlue, 10%);
    }
  }

  p, li {
    color: #000;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4em;
    margin: 0 0 10px;
    @include tablet {
      font-size: 15px;
      margin: 0 0 20px;
    }
    @include desktop {
      font-size: 16px;
      margin: 0 0 25px;
    }

    &.caption {
      color: #999;
      font-size: 12px;
    }
  }
  li {

    margin: 0 auto 15px;
  }

  h2 {
    color: #000;
    font-weight: bold;
    font-size: 20px;
    margin: 0 0 20px;
    display: inline-block;
    @include tablet {
      font-size: 22px;
      margin: 0 0 20px;
    }
    @include desktop {
      font-size: 34px;
      margin: 0 0 30px;
    }
  }

  h3 {
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0 10px;
    display: block;
    @include tablet {
      font-size: 20px;
      margin: 10px 0 10px;
    }
    @include desktop {
      font-size: 26px;
      margin: 10px 0 15px;
    }
  }

  a.view-more {
    color: #555;
    font-weight: bold;
    font-size: 12px;
    display: block;
    position: relative;
    top: -10px;
    text-transform: uppercase;
    @include tablet {
      font-size: 14px;
      display: inline-block;
      float: right;
      top: 8px;
    }
    @include desktop {
      font-size: 16px;
    }

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 4px;
    height: 0;
    margin: 20px 0;
    @include tablet {
      margin: 30px 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .img-responsive {
    max-width: 100% !important;
    margin: 20px 0;
    @include tablet {
      margin: 10px 0 25px;
    }
    @include desktop {
      margin: 10px 0 30px;
    }
    @include large-desktop {
      margin: 10px 0 35px;
    }
  }

  .blue-box,
  .grey-box,
  .yellow-box {
    display: block;
    background-color: #C4E3F4;
    margin: 15px 0 25px;
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;
    @include tablet {
      margin: 20px 0 30px;
      padding: 20px;
      font-size: 16px;
    }
  }

  .grey-box {
    background-color: #EFEFEF;
  }

  .yellow-box {
    background-color: #FFF6D8;
  }

  .lesson-button {
    background-color: #00B257;
    padding: 15px 10px;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFF;
    font-size: 16px;
    border-radius: 5px;
    @include tablet {
      padding: 20px 10px;
      font-size: 20px;
    }

    &:hover {
      background-color: #03C863;
      text-decoration: none;
      color: #FFF;
    }
  }

  .lesson-list {
    margin: 0 auto 20px;
    @include desktop {
      margin: 0 auto 40px;
    }

    h3 {
      width: 100%;
      color: #000;
      font-weight: 400;
    }

    .listing-box {
      margin: 20px 0;

      ol, ul {
        counter-reset: li;
        margin-left: 2em;
        padding-left: 0;

        li {
          position: relative;
          margin: 0 0 11px;
          padding: 0 21px 0 10px;
          list-style: none;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            top: -2px;
            left: -21px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            margin: 0 0 8px;
            color: #FFF;
            background: #000;
            font-weight: bold;
            border-radius: 50%;
            text-align: center;
            padding: 0;
            line-height: 20px;
          }

          ol, ul {
            margin-top: 6px;
          }
        }
      }

      ul li:before {
        font-family: 'FontAwesome', sans-serif;
        content: "\f054";
      }
    }

  }

  .resource-links {
    width: 100%;
    margin: 0 0 15px;
    @include tablet {

      margin: 0 0 25px;
    }
    @include desktop {

      margin: 0 0 30px;
    }
  }

  a.live-resource {
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #2E2E2E;
    border: 2px solid #2E2E2E;
    margin: 10px 0 10px;
    padding: 12px;
    border-radius: 5px;
    text-transform: uppercase;
    text-decoration: none !important;
    @include tablet {

      font-size: 18px;
      padding: 16px;
    }
    @include desktop {

      font-size: 20px;
      padding: 20px;
    }

    &:hover {
      background-color: #000;
      color: #FFF;
    }

    .resource-icon {
      float: right;
    }
  }

  .product-grid {
    width: 100%;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;

    .columns {
      margin: 0 auto 30px;

      .product-grid-item {
        text-align: center;
        border: 1px solid #dedede;
        border-radius: 5px;
        padding: 20px;

        h6 {
          font-weight: 700;
          margin: 15px auto;
          min-height: 44px;
          font-size: 16px;
        }

        p {
          margin: 0 auto;
          font-size: 16px;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .the-greats {
    width: 100%;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .columns {
      margin: 0 auto 15px;

      a {
        position: relative;
        display: block;

        &:hover {
          opacity: 0.8;

          i {
            opacity: 1;
          }
        }
      }

      img {
        border-radius: 50%;
      }

      i {
        position: absolute;
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-shadow: 0 0 15px #000;
        font-size: 30px;
        opacity: 0;
        transition: opacity .3s;
      }

      p {
        font: 400 16px/1em $opensans;
        margin: 7px auto 0;
        color: #000;
      }
    }
  }

  .albums {
    width: 100%;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .columns {
      margin: 0 auto 20px;

      img {
        border-radius: 5px;
      }

      p {
        font: 400 14px/1.2em $opensans;
        margin: 7px auto 0;
      }
    }
  }


  .slider-wrap {
    padding: 0 0 30px;
    margin: 0 auto 15px;
    overflow: hidden;
    max-height: 490px;
    @include desktop {
      max-height: 530px;
      overflow: visible;
    }

    .center {
      margin: 0 auto;
      padding: 0;
      opacity: 0;
      width: 100%;
      max-width: 490px;
      transition: opacity .4s ease-in;
      @include tablet {
        max-width: 750px;
      }

      &.slick-initialized {
        opacity: 1;
      }


      .slick-list {
        &::after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .slick-slide {
        font-size: 0;

        img {
          border-radius: 5px;
          transition: opacity 0.3s ease-in;
          opacity: 0.5;
        }

        &.slick-active, &.slick-current {
          img {
            opacity: 1;
          }
        }
      }

      .slick-arrow {
        z-index: 2;
        width: 30px;
        height: 30px;
        background: #000;
        border: 2px solid #fff;
        border-radius: 50%;

        &.slick-next:before,
        &.slick-prev:before {
          display: inline-block;
          font: normal normal normal 17px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          opacity: 1;
        }

        &.slick-next:before {
          content: "\f054";
        }

        &.slick-prev:before {
          content: "\f053";
        }

        &.slick-prev {
          left: 7px;
          @include tablet {
            left: 15px;
          }
        }

        &.slick-next {
          right: 7px;
          @include tablet {
            right: 15px;
          }
        }
      }

      .slick-dots {
        margin: 0 auto -5px;
        li {
          margin: 0 5px;

          button:before {
            font-size: 14px;
          }
        }
      }

    }
  }

  .small-up-1 > .column, .small-up-1 > .columns {
    float: left;
    width: 100%;
  }

  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }

  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left;
  }

  .small-up-2 > .column, .small-up-2 > .columns {
    float: left;
    width: 50%;
  }

  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }

  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left;
  }

  .small-up-3 > .column, .small-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }

  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }

  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left;
  }

  .small-up-4 > .column, .small-up-4 > .columns {
    float: left;
    width: 25%;
  }

  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }

  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left;
  }

  .small-up-5 > .column, .small-up-5 > .columns {
    float: left;
    width: 20%;
  }

  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }

  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left;
  }

  .small-up-6 > .column, .small-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }

  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }

  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left;
  }

  .small-up-7 > .column, .small-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }

  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }

  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left;
  }

  .small-up-8 > .column, .small-up-8 > .columns {
    float: left;
    width: 12.5%;
  }

  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }

  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left;
  }

  @media (min-width: 640px) {
    .medium-up-1 > .column, .medium-up-1 > .columns {
      float: left;
      width: 100%;
    }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both;
    }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left;
    }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      float: left;
      width: 50%;
    }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both;
    }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left;
    }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      float: left;
      width: 33.33333%;
    }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both;
    }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left;
    }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      float: left;
      width: 25%;
    }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both;
    }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left;
    }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      float: left;
      width: 20%;
    }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both;
    }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left;
    }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      float: left;
      width: 16.66667%;
    }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both;
    }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left;
    }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      float: left;
      width: 14.28571%;
    }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both;
    }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left;
    }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      float: left;
      width: 12.5%;
    }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both;
    }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left;
    }
  }
  @media (min-width: 1024px) {
    .large-up-1 > .column, .large-up-1 > .columns {
      float: left;
      width: 100%;
    }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both;
    }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left;
    }
    .large-up-2 > .column, .large-up-2 > .columns {
      float: left;
      width: 50%;
    }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both;
    }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left;
    }
    .large-up-3 > .column, .large-up-3 > .columns {
      float: left;
      width: 33.33333%;
    }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both;
    }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left;
    }
    .large-up-4 > .column, .large-up-4 > .columns {
      float: left;
      width: 25%;
    }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both;
    }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left;
    }
    .large-up-5 > .column, .large-up-5 > .columns {
      float: left;
      width: 20%;
    }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both;
    }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left;
    }
    .large-up-6 > .column, .large-up-6 > .columns {
      float: left;
      width: 16.66667%;
    }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both;
    }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left;
    }
    .large-up-7 > .column, .large-up-7 > .columns {
      float: left;
      width: 14.28571%;
    }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both;
    }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left;
    }
    .large-up-8 > .column, .large-up-8 > .columns {
      float: left;
      width: 12.5%;
    }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none;
    }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both;
    }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left;
    }
  }
}

/* ------LIVE BROADCASTING */

.live-video-box {
  margin: 15px 0;
  @include tablet {
    margin: 20px 0;
  }
  @include desktop {
    margin: 30px 0;
  }

  .live-wrapper {
    width: 100%;
    float: left;
    background-color: #FFF;
    border-radius: 5px;

    .live-video {
      width: 100%;
      float: left;
      @include desktop {
        width: 660px;
        float: left;
      }
      @include large-desktop {
        width: 840px;
      }

      .video-top {
        padding: 12px 7px 12px 7px;
        margin: 0;
        border-radius: 5px 5px 0 0;
        text-align: center;
        background-color: #101213;
        @include tablet {
          padding: 25px 30px 30px 30px;
          text-align: left;
          display: block;
          font-size: 0;
        }
        @include desktop {
          border-radius: 5px 0 0 0;
          padding: 25px 30px 30px 30px;
          text-align: left;
        }

        .left {
          width: 100%;
          @include tablet {
            width: 80%;
            display: inline-block;
          }
        }

        .right {
          width: 100%;
          @include tablet {
            width: 20%;
            display: inline-block;
          }
        }

        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #FFF;
          margin: 0;
          @include tablet {
            font-size: 30px;
          }
          @include desktop {
            font-size: 34px;
          }
          @include large-desktop {
            font-size: 38px;
          }
        }

        p {
          margin: 10px 0 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 100;
          color: #FFF;
          @include tablet {
            font-size: 14px;
          }
          @include desktop {
            font-size: 16px;
          }
        }

        .live-bubble {
          background-color: #FF2A3F;
          padding: 3px 6px;
          border-radius: 0 5px 0 5px;
          font-size: 8px;
          color: #FFF;
          font-weight: bold;
          text-transform: uppercase;
          text-align: right;
          display: inline-block;
          float: right;
          position: absolute;
          top: -10px;
          right: 15px;
          @include tablet {
            padding: 10px 20px;
            border-radius: 20px;
            font-size: 16px;
            position: relative;
            text-align: right;
            top: -4px;
            right: 0;
          }
          @include desktop {
            top: -8px;
            right: 0;
          }
          @include large-desktop {
            top: -10px;
            right: 0;
          }
        }
      }

      .video {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 4px;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }

      .video-navigation {
        display: none;
        width: 100%;
        background-color: #101213;
        /*background-color:#fff;*/
        height: 8px;
        border-radius: 0 0 0 5px;
        float: left;
        @include desktop {
          display: inline-block;
          height: 15px;
        }
      }
    }

    .live-chat {
      width: 100%;
      float: left;
      background-color: #FFF;
      border-radius: 0 0 5px 5px;
      height: 100%;
      padding: 20px 20px 5px 20px;
      position: relative;
      @include desktop {
        width: 280px;
        float: left;
        border-radius: 0 5px 0 0;
        padding: 0 20px;
      }
      @include large-desktop {
        width: 300px;
      }

      .mobile-chat {
        position: relative;
        padding-bottom: 100%;
        padding-top: 4px;
        height: 0;
        @include tablet {
          display: none;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }

      .login-overlay {
        position: absolute;
        bottom: 0;
        background-color: #FFF;
        height: 70px;
        width: 90%;
        z-index: 999;
        @include tablet {
          height: 80px;
          width: 680px;
        }
        @include desktop {
          height: 102px;
          width: 240px;
        }
        @include large-desktop {
          width: 260px;
        }

        .chat-login-button {
          background-color: #108FE7;
          width: 100%;
          display: block;
          padding: 20px 10px;
          color: #FFF;
          text-align: center;
          font-size: 12px;
          margin: -40px 0 0;
          text-transform: uppercase;
          border-radius: 5px;
          @include tablet {
            width: 100%;
            padding: 20px;
            font-size: 14px;
            margin: -30px 0 0;
          }
          @include desktop {
            width: 100%;
            padding: 15px 10px;
            font-size: 16px;
            margin: 10px 0 0;
          }
          @include large-desktop {
            margin: 15px 0 0;
          }

          &:hover, &:active, &:visited {
            background-color: #2BA7FF;
            text-decoration: none;
            color: #FFF;
          }
        }
      }
    }
  }
}

/* ------CONTACT */

.contact-form {
  width: 100%;
  margin: 30px 0 0;
  @include tablet {
    margin: 20px 0 0;
  }

  input {
    width: 100%;
    margin: 0 0 10px;
    height: 40px;
    font-size: 14px;
    border: 1px solid #999;
    border-radius: 5px;
    background-color: #FFF;
    padding: 0 15px 0 15px;
    color: #000;
    font-weight: 100;
    -webkit-appearance: none;
    @include tablet {
      width: 44%;
      margin: 0 14px 20px;
      height: 50px;
      font-size: 18px;
    }
  }

  textarea {
    width: 100%;
    margin: 0 0 10px;
    font-size: 14px;
    border: 1px solid #999;
    border-radius: 5px;
    background-color: #FFF;
    color: #000;
    font-weight: 100;
    -webkit-appearance: none;
    height: 100px;
    padding: 15px;
    resize: vertical;
    line-height: 1.4em;
    @include tablet {
      margin: 0 14px 20px;
      font-size: 18px;
      width: 100%;
      height: 140px;
    }
  }

  .submit-button {
    background-color: #108FE7;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #FFF;
    border: none;
    border-radius: 5px;
    display: block;
    height: 40px;
    width: 230px;
    transition: 0.2s;
    @include tablet {
      font-size: 18px;
      height: 50px;
    }

    &:hover {
      background-color: #34A9F1;
    }
  }
}

/* ------CARD FORMAT */

.card-box {
  margin: 10px auto;
  padding: 0 7px;
  @include tablet {
    padding: 0;
  }

  .card {
    margin: 0 auto 20px;
    display: block;
    position: relative;
    text-decoration: none;
    @include tablet {
      margin: 0 auto 30px;
    }

    .card-thumb {
      position: relative;
      margin: 0 auto 7px;
      border-radius: 5px;
      padding-bottom: 56.5%;
      background: #000;
      overflow:hidden;

      .thumbnail-pic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000 url("/includes/images/nate-place.jpeg") center center/cover no-repeat;
        transition: transform 4s ease;
        transform: scale(1.01);

      }

      .badge {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $glYellow;
        padding: 4px 7px;
        border-radius: 5px 0 5px;
        font: 700 12px/1em $robotoCondensed;
        text-transform: uppercase;
        color: #000;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 30px;
        transform: translate(-50%, -50%);
        text-shadow: 0 0 15px #000;
        opacity: 0;
        transition: opacity .3s;
      }
    }


    .card-text {
      font: 400 11px/1.2em $opensans;
      color: #999;
      text-transform: uppercase;
      text-decoration: none !important;
      @include tablet {
        font-size: 12px;
      }

      strong {
        text-transform: none;
        font-size: 14px;
        line-height: 1.2em;
        color: #000;
        display: inline-block;
        margin: 0 auto 5px;
        @include tablet {
          font-size: 17px;
        }
      }
    }

    &:hover .card-thumb {
      .thumbnail-pic {
        transform: scale(1.11);
      }

      i {
        opacity: 1;
      }
    }
  }

  #loadMore {
    display: block;
    margin: 0 auto 30px;
    max-width: 500px;
    color: #70767F;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #70767F;
    @include tablet {

      max-width: 500px;
      font-size: 16px;
      padding: 15px;
    }

    &:hover {
      background-color: #70767F;
      color: #FFF;
    }
  }
}

/* ------BOTTOM SIGNUP BOX */

.bottom-signup-box {
  width: 100%;
  background: #1c1e1e;
  padding: 30px 0;
  margin-bottom: -30px;
  @include tablet {
    padding: 50px 0;
  }
  @include desktop {
    padding: 60px 0;
  }
  @include large-desktop {
    padding: 70px 0;
  }

  img.tugt-logo {
    width: 100%;
    max-width: 270px;
    display: block;
    margin: 0 auto 10px;
    @include tablet {
      width: 100%;
      max-width: 380px;
      margin: 0 auto 10px;
    }
  }

  .signup-text {
    color: #FFF;
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 5px;
    @include tablet {
      font-size: 19px;
      width: 70%;
      margin: 0 auto;
      max-width: none;
    }
    @include desktop {
      font-size: 20px;
      width: 84%;
      line-height: 1.3em;
    }
    @include large-desktop {
      font-size: 24px;
      width: 84%;
      line-height: 1.5em;
    }
  }

  img.tugt-image {
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    border: 10px solid #fff;
    box-shadow: 0 0 10px #000;
    @include tablet {
      max-width: 440px;
    }
    @include desktop {
      width: 100%;
    }
  }

  .form-box {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    @include tablet {
      width: 84%;
      margin: 0 auto;
      max-width: none;
    }
    @include desktop {
      width: 100%;
    }

    .left-arrow {
      float: left;
      width: 8%;
      padding: 70px 10px 0;
      display: none;
      @include tablet {
        padding: 60px 10px 0;
        display: inline-block;
      }
      @include desktop {
        width: 8%;
        padding: 75px 10px 0;
      }
      @include large-desktop {
        float: left;
        width: 8%;
        padding: 70px 10px 0;
      }
    }

    .right-arrow {
      float: left;
      width: 8%;
      display: none;
      padding: 70px 0 0 10px;
      @include tablet {
        display: inline-block;
        padding: 60px 0 0 10px;
      }
      @include desktop {
        width: 8%;
        padding: 75px 0 0 10px;
      }
      @include large-desktop {
        float: left;
        width: 8%;
        padding: 70px 0 0 10px;
      }
    }

    .left-arrow img,
    .right-arrow img {
      width: 100%;
    }

    .regi-form {
      float: left;
      width: 100%;
      @include tablet {
        width: 84%;
      }
    }
  }

  .sign-up-form {
    width: 100%;
    margin: 0 auto;

    input[type="text"] {
      background: none repeat scroll 0 0 #FFF;
      border: none;
      border-radius: 5px;
      width: 100%;
      height: 50px;
      color: #333;
      font-family: $opensans;
      font-weight: 300;
      font-size: 16px;
      padding: 0 20px;
      margin: 10px auto 10px;
      position: relative;
      -webkit-appearance: none;
      -webkit-transition: all .3s;
      transition: all .3s;
      display: block;
      outline: none;
      @include tablet {
        font-size: 20px;
        height: 60px;
        margin: 20px auto 10px;
        max-width: none;
      }
      @include desktop {
        margin: 14px auto 10px;
      }
      @include large-desktop {
        margin: 20px auto 10px;
      }
    }

    .submit {
      background-color: #ff892c;
      border: none;
      padding: 10px 10px;
      text-decoration: none;
      -webkit-transition: all .3s;
      transition: all .3s;
      margin: 0 auto;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
      cursor: hand;
      -webkit-appearance: none;
      display: block;
      font-family: $opensans;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #FFF;
      text-transform: uppercase;
      width: 100%;
      @include tablet {
        font-size: 20px;
        max-width: none;
        height: 60px;
      }

      &:hover {
        background-color: #ffa65f;
      }
    }
  }
}

/* ------MODAL */

.modal {
  display: none;
  position: fixed;
  padding-top: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}


@-webkit-keyframes animatefade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes animatefade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ------MODAL SIGN UP FORM */

.sign-up-form {
  width: 100%;
  margin: 0 auto;
  @include tablet {

    width: 90%;
  }
  @include desktop {

    width: 80%;
  }

  input[type="text"],
  input[type="email"]
  {
    background: none repeat scroll 0 0 #FFF;
    border: 1px solid #CCC;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    color: #666;
    font-family: $opensans;
    font-weight: 300;
    font-size: 16px;
    padding: 7px 6px 8px 10px;
    position: relative;
    transition: all .3s;
    display: block;
    float: left;
    outline: none;
    margin: 0 0 10px;
    @include tablet {

      width: 60%;
      height: 50px;
      font-size: 18px;
      padding: 7px 6px 8px 20px;
      display: inline-block;
      margin: 0 0 20px;
    }
    @include desktop {

      height: 60px;
      font-size: 20px;
    }
  }

  .submit {
    background-color: #328BEB;
    border: none;
    padding: 6px 10px 12px 10px;
    text-decoration: none;
    transition: all .3s;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    cursor: hand;
    /*text-shadow: 1px 1px #508253;*/
    -webkit-appearance: none;
    font-family: $opensans;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
    display: block;
    width: 100%;
    margin: 0;
    @include tablet {

      padding: 11px 30px 12px 30px;
      margin: 0 0 0 3%;
      height: 50px;
      font-size: 18px;
      width: 37%;
      display: inline-block;
    }
    @include desktop {

      padding: 13px 30px 12px 30px;
      height: 60px;
      font-size: 20px;
    }

    &:hover {
      background-color: #4EA4FF;
    }
  }
}

iframe.thank-you {
  width: 100%;
  max-width: 960px;
  border-radius: 5px;
  height: 0;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .4s ease-in;
  transition: all .4s ease-in;
  display: block;
  margin: 0 auto;
  background: #FFF;

  @include tablet {
    height: 350px;
  }

  &.active {
    max-height: 100%;
    height: 340px;
    visibility: visible;
    opacity: 1;
  }
}